import { CommonMargin } from 'components/_layout/CommonMargin';
import SEO from 'components/_layout/Seo';
import React from 'react';
import { RelativeSection } from 'sections/Offer/Offer.styled';
import { useBreakpoint } from 'utils/hooks/useBreakpoints';
import styled from 'styled-components';
import { BenefitsSection } from 'sections/DeepTech/ImageAiProcessing/components/BenefitsSection';
import { ImageWithTextSection } from 'sections/DeepTech/ImageAiProcessing/components/ImageWithTextSection';
import { ChooseCodaheadSection } from 'sections/DeepTech/ImageAiProcessing/components/ChooseCodaheadSection';
import { DistinctSection } from 'sections/DeepTech/ImageAiProcessing/components/DistinctSection';
import { OurOfferSection } from 'sections/_universal/OurOfferSection';
import { TakeThisStepSection } from 'sections/_universal/TakeThisStepSection';

export default () => {
  const isMd = useBreakpoint('md');

  return (
    <>
      <SEO
        title="AI Image Analysis procesing service: advanced OCR solutions"
        meta={[
          {
            name: 'keywords',
            content:
              'ai image processing services, image ai processing services, object detection, ai image analysis software, ai image analysis, optical character recognition services, ai software for image analysis, ocr services, ocr scanning services, online ocr services',
          },
        ]}
        description={
          'Codahead offers AI software for image analysis, optical character recognition (OCR), and advanced image processing services. Contact us today!'
        }
      />

      <CommonMargin>
        <StyledSection
          title="Image AI processing"
          variant="secondary"
          id="Image AI processing"
          isMobile={isMd}
          withBreadCrumbs
        >
          <HeaderSection>
            The ability to analyze and interpret images is no longer a luxury—it’s a necessity. Our Image AI Processing
            services leverage state-of-the-art machine learning and computer vision algorithms to unlock the full
            potential of your images. Whether you’re working on object detection, facial recognition, or automated image
            tagging, our AI-powered solutions help you turn data into actionable insights. We deliver smarter, more
            efficient ways to work with images, driving innovation and enhancing user experience across industries.
            <br />
            <br />
            Data is the lifeblood that powers innovation. However, raw data alone isn’t enough—you need the right data
            sets and properly trained neural networks to drive intelligent decision-making and automation. We offer
            comprehensive training services for both data sets and neural networks, helping you unlock the full
            potential of AI. Whether you need custom datasets, model fine-tuning, or in-depth neural network training,
            our solutions provide you with the expertise and tools to stay ahead of the competition.
          </HeaderSection>
        </StyledSection>

        <DistinctSection />
        <ImageWithTextSection />
        <ChooseCodaheadSection />
        <BenefitsSection />
        <OurOfferSection />
        <TakeThisStepSection />
      </CommonMargin>
    </>
  );
};

const HeaderSection = styled.div`
  margin-top: 40px;
  max-width: 900px;
  font-size: 20px;
  line-height: 1.65;
  font-weight: 300;
`;

const StyledSection = styled(RelativeSection)`
  margin-top: 24px;
`;
